import { KeysAvailableProperties, KeyTypes } from './interfaces';

const getJSONParse = (data: string | null, returnDataWhenFail?: boolean): any | false => {
	try {
		const parsedData = JSON.parse(data!);
		return parsedData;
	} catch {
		if (returnDataWhenFail) return data;

		return false;
	}
};

export const storageKeys: KeysAvailableProperties = {
	TOKEN: '@PHC:Token',
	PHARMACY: '@PHC:Pharmacy',
	MY_PHARMACIES: '@PHC:MyPharmacies',
	BASKET_END_DATE: '@PHC:BasketValidTill',
	EMAIL: '@PHC:Email',
	IMPORT_CLOSED_BASKETS: '@PHC:ImportClosedBaskets',
	HAS_DISTRIBUTOR: '@PHC:HasDistributor',
	FIRST_ACCESS: '@PHC:FirstAccess',
	POS_REGISTER_DATA: '@PHC:POSRegisterData',
	BASKET_PRODUCTS_UNAVAILABLE: '@PHC:BasketProductsUnavailable',
	ALERT_SETTINGS: '@PHC:AlertSettings',
	ROUTE_NOT_LOGGED: '@PHC:RouteNotLogged',
};

export const replaceTokenQuotes = (token?: string | null): string => {
	const needReplace = token?.startsWith('"') && token?.endsWith('"');

	if (!needReplace) return token || '';

	return token?.replaceAll(/["]/g, '') || '';
};

export const manageStorage = (storageType: 'localStorage' | 'sessionStorage') => {
	return {
		get<T>(key: KeyTypes): T {
			return getJSONParse(window[storageType].getItem(storageKeys[key]), true);
		},
		set(key: KeyTypes, value: any) {
			window[storageType].setItem(storageKeys[key], value);

			return manageStorage(storageType);
		},
		remove(key: KeyTypes) {
			window[storageType].removeItem(storageKeys[key]);

			return manageStorage(storageType);
		},
		removeAll() {
			// ? It's necessary to always put new keys here one by one to make sure they're all clean.

			// Local Storage Keys
			localStorage.removeItem(storageKeys.TOKEN);
			localStorage.removeItem(storageKeys.PHARMACY);
			localStorage.removeItem(storageKeys.MY_PHARMACIES);
			localStorage.removeItem(storageKeys.BASKET_END_DATE);

			// Session Storage Keys
			sessionStorage.removeItem(storageKeys.EMAIL);

			return manageStorage(storageType);
		},
	};
};

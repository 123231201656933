import { Typography } from 'front-commons/ds';
import {
	DialogState,
	ShowUnavailableProductsDialogParams,
} from 'containers/Drawers/PromotionDetailDrawerContent/interfaces';
import UnavailablePromotionProductsTable from 'containers/Tables/UnavailablePromotionProductsTable';
import { Summary } from 'contexts/Promotion/interfaces';
import { FindBasketProductsResponse } from 'services/basket/interfaces';
import { GetPromotionInfoResponse, ProductInComboProps } from 'services/products/interfaces';
import { GetDialogProps, HasUnableToUpdateParams } from './interfaces';

export const getSummaryQuantities = (summary: Summary[]) => summary.reduce((acc, curr) => curr.quantity + acc, 0);

export const getSummaryBrutePrice = (summary: Summary[]) =>
	summary.reduce((acc, curr) => curr.quantity * curr.price + acc, 0);

export const getCurrentComponentRange = (
	rangeByQuantity: boolean,
	progressiveDiscount: boolean,
	summary: Summary[],
	currentQuantityInBasket = 1,
) => {
	if (rangeByQuantity)
		return progressiveDiscount ? currentQuantityInBasket : getSummaryQuantities(summary) * currentQuantityInBasket;
	return getSummaryBrutePrice(summary) * currentQuantityInBasket;
};

export const handlerDistributorValidity = (data: GetPromotionInfoResponse) =>
	!!data?.distributors?.length && data?.distributors.some(({ status }) => status !== 'UNAVAILABLE');

export const hasQuantityChanged = (
	currentData: Summary[],
	promotionId: string,
	initialData?: FindBasketProductsResponse,
) => {
	const productsInCombo = initialData?.baskets
		?.flatMap(({ products }) => products)
		.find(({ id }) => id === promotionId)?.productsInCombo;

	const hasAnyChangesInQuantity =
		productsInCombo?.map(
			({ quantity, productId }) =>
				quantity === currentData.find((product) => product.productId === productId)?.quantity,
		) || [];

	if (currentData?.length !== hasAnyChangesInQuantity.length) {
		const productsWithQuantitiesZero =
			currentData?.reduce<boolean[]>((prev, { productId, quantity }) => {
				if (!productsInCombo?.find((p) => p.productId === productId)) {
					prev.push(quantity === 0);
				}
				return prev;
			}, []) || [];

		const hasAnyChanges = [...productsWithQuantitiesZero, ...(hasAnyChangesInQuantity || [])];
		return !hasAnyChanges.every(Boolean);
	}

	return !hasAnyChangesInQuantity.every(Boolean);
};

export const getDaysToEnd = (to: string) => {
	const { days } = Date.getDiff({ to });
	return days;
};

export const getDiscountPrice = (discount: number, price: number) => (discount / 100 - 1) * -1 * price;

export const hasUnableToUpdate = ({
	addedProductsInComboUnits: addedProductsInCombo,
	currentQuantityInBasket,
	hasQuantityChanged: quantityChanged,
	hasValidDistributors,
	minQuantityDistinctProducts,
}: HasUnableToUpdateParams) => {
	if (minQuantityDistinctProducts && addedProductsInCombo < minQuantityDistinctProducts) return true;

	if (!hasValidDistributors) {
		return true;
	}

	if (currentQuantityInBasket) {
		if (quantityChanged) return false;
		return true;
	}

	return false;
};

export const getRemainingDaysText = (date: string) => {
	const daysToEnd = getDaysToEnd(date);

	if (daysToEnd < 0) return '';

	if (daysToEnd === 0) return 'Último dia da promoção!';

	return (
		<Typography whiteSpace="nowrap">
			<strong>{daysToEnd}</strong> dia{daysToEnd > 1 ? 's' : ''} para terminar a promoção
		</Typography>
	);
};

export const getInitialQuantity = (
	fixedAmount: boolean,
	minimumQuantity: number | undefined,
	flexBasketQuantity: number | undefined,
	productQuantity: number,
	hasValidDistributors: boolean,
) => {
	if (!hasValidDistributors) return 0;
	if (fixedAmount) return minimumQuantity || 1;
	return flexBasketQuantity || productQuantity;
};

export const getUnavailableProductsDialogContent = ({
	distributor,
	productsUnavailable,
	isAllUpdatedProductsUnavailable,
}: ShowUnavailableProductsDialogParams) => {
	const dialogDescription = isAllUpdatedProductsUnavailable
		? 'Todos os produtos escolhidos não estão disponíveis no distribuidor'
		: 'Os produtos listados abaixo não estão disponíveis no distribuidor';

	return {
		description: (
			<>
				{dialogDescription} <strong>{distributor.distributorName}</strong>, caso deseje prosseguir, os itens serão{' '}
				<strong>removidos</strong> do seu carrinho.
			</>
		),
		children: isAllUpdatedProductsUnavailable ? undefined : (
			<UnavailablePromotionProductsTable
				data={productsUnavailable.map(({ description, ean13, quantity }) => ({ description, ean13, quantity }))}
			/>
		),
	};
};

export const getDialogProps = (state: DialogState, action?: GetDialogProps) => {
	if (state === 'conflict')
		return {
			heading: { title: 'Conflito de produtos', showCloseButton: true },
			content: {
				description:
					'Já existe esse produto em seu carrinho. Para comprá-lo com outra condição comercial, você deve removê-lo do carrinho.',
			},
			footer: {
				primaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
				secondaryButton: { label: 'Cancelar' },
			},
		};

	if (state === 'distributor')
		return {
			heading: {
				title: 'Deseja continuar?',
				showCloseButton: true,
			},
			content: {
				description:
					'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
			},
			onClose: action?.handleDialogClose,
			footer: {
				primaryButton: { label: 'Continuar', onClick: action?.handleRemovePromotion },
				secondaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
			},
		};

	if (state === 'no_value')
		return {
			heading: {
				title: 'Combo sem quantidade',
				showCloseButton: true,
			},
			content: {
				description: 'Adicione a quantidade dos produtos desejada para continuar',
			},
			footer: {
				primaryButton: { label: 'Entendi' },
			},
		};

	return {
		heading: {
			title: 'Atualizar carrinho',
			showCloseButton: true,
		},
		content: {
			description: 'Foram adicionados mais itens ao seu combo, para prosseguir você deve atualizar o seu carrinho.',
		},
		footer: {
			primaryButton: { label: 'Voltar a promoção' },
		},
	};
};

export const sortOptions = [
	{
		identifier: 1,
		name: 'Maior preço',
	},
	{
		identifier: 2,
		name: 'Menor preço',
	},
	{
		identifier: 3,
		name: 'Ordem alfabética',
	},
	{
		identifier: 4,
		name: 'Produtos já selecionados',
	},
];

export const reorderItems = (
	data: ProductInComboProps[] | undefined | null,
	selectedDistributor: string,
	sortSelected?: number,
	selectedProducts?: string[],
) => {
	if (!sortSelected) return data;

	if (sortSelected === 1) {
		const sortedProducts = data!
			.filter((product) =>
				product.distributorsPrice.some(
					(distributor) => distributor.distributorId === selectedDistributor && distributor.price !== null,
				),
			)
			.sort((productA, productB) => {
				const priceA =
					productA.distributorsPrice.find(
						(distributor) => distributor.distributorId === selectedDistributor && distributor.valid,
					)?.price || 0;

				const priceB =
					productB.distributorsPrice.find(
						(distributor) => distributor.distributorId === selectedDistributor && distributor.valid,
					)?.price || 0;

				const validPriceA = priceA !== undefined ? priceA : 0;
				const validPriceB = priceB !== undefined ? priceB : 0;

				return validPriceB - validPriceA;
			});

		return sortedProducts;
	}

	if (sortSelected === 2) {
		const sortedProducts = data!
			.filter((product) =>
				product.distributorsPrice.some(
					(distributor) => distributor.distributorId === selectedDistributor && distributor.price !== null,
				),
			)
			.sort((productA, productB) => {
				const priceA =
					productA.distributorsPrice.find(
						(distributor) => distributor.distributorId === selectedDistributor && distributor.valid,
					)?.price || 9999999999;

				const priceB =
					productB.distributorsPrice.find(
						(distributor) => distributor.distributorId === selectedDistributor && distributor.valid,
					)?.price || 9999999999;

				const validPriceA = priceA !== undefined ? priceA : 9999999999;
				const validPriceB = priceB !== undefined ? priceB : 9999999999;

				return validPriceA - validPriceB;
			});

		return sortedProducts;
	}

	if (sortSelected === 3) {
		data?.sort((a, b) => {
			return a.productInfo.description.localeCompare(b.productInfo.description);
		});
	}

	if (sortSelected === 4 && selectedProducts && selectedProducts.length > 0) {
		data?.sort((a, b) => {
			const aIndex = selectedProducts.indexOf(a.productId);
			const bIndex = selectedProducts.indexOf(b.productId);
			if (aIndex === -1 && bIndex === -1) return 0;
			if (aIndex === -1) return 1;
			if (bIndex === -1) return -1;
			return aIndex - bIndex;
		});
	}

	return data;
};

// Please do not remove any commentary, all them are used on script generating

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import customerReducer from './customer/reducer';
import dialogReducer from './dialog/reducer';
import posReducer from './pos/reducer';
import fidelityReducer from './fidelity/reducer';
import drawerReducer from './drawer/reducer';
import { PersistConfigParams } from './interfaces';
import catalogReducer from './catalog/reducer';
import policyReducer from './policy/reducer';
import basketReducer from './basket/reducer';
import orderImportReducer from './orderImport/reducer';
// imports

const persistConfig: PersistConfigParams = {
	key: '@PHC',
	storage,
	blacklist: ['dialogReducer', 'drawerReducer'],
};

export const rootReducer = combineReducers({
	customerReducer,
	dialogReducer,
	posReducer,
	fidelityReducer,
	drawerReducer,
	catalogReducer,
	policyReducer,
	basketReducer,
	orderImportReducer,
}); // reducers

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk]
});

export const persistor = persistStore(store);

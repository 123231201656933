import { Flex, ProgressiveDiscountCard } from 'front-commons/ds';
import { ProgressiveDiscountState } from 'front-commons/ds/components/ProgressiveDiscountCard/interfaces';
import { useMemo } from 'react';
import { ProgressiveDiscountProps } from './interfaces';

export default function ProgressiveDiscountCards({ data, atualValue, onClick }: ProgressiveDiscountProps) {
	const formattedRanges = useMemo(
		() =>
			data.values.map((range, index) => {
				const lastRange = data.values[index - 1];
				let state: ProgressiveDiscountState = '';

				if (atualValue >= range.from) {
					state = 'active';
				}

				if (atualValue >= Number(lastRange?.from) && atualValue <= Number(lastRange?.to)) {
					state = 'next';
				}

				return { ...range, state };
			}),
		[data, atualValue],
	);

	return (
		<Flex gap={{ small: '16px 4px', medium: '16px 8px', large: '8px' }} wrap="wrap">
			{formattedRanges.map((range, index) => (
				<ProgressiveDiscountCard
					key={index}
					icon="sale"
					range={range}
					rangeByQuantity={data.type === 'quantity'}
					onClick={onClick && (() => onClick?.({ range, index }))}
					state={range.state}
				/>
			))}
		</Flex>
	);
}

import { Typography } from 'front-commons/ds';
import { GetProgressPercentageParams } from './interfaces';

export const getPercentage = (current: number, max: number, min?: number) => {
	if (min && current + min < min) return 0;
	return (current / max) * 100;
};

export const stepNumber = (simplified: boolean, completed: boolean, index: number, summary: boolean) => {
	if (!simplified && !completed)
		return (
			<Typography
				color={completed || simplified ? '--brand-fidelity100' : '--surface-primary'}
				variant="Paragraph/Semibold"
				style={{ fontSize: summary ? '8px' : '14px' }}
			>
				{index}
			</Typography>
		);

	if (simplified && !completed)
		return (
			<Typography
				color={completed || simplified ? '--brand-fidelity100' : '--surface-primary'}
				variant="ParagraphSmall/Semibold"
			>
				{index}
			</Typography>
		);

	return null;
};

export const getProgressPercentage = ({ max, current, min }: Omit<GetProgressPercentageParams, 'missionNumber'>) => {
	const percentage = getPercentage(current, max, min);

	if (max === 0) return 0;

	return percentage < 10 ? 10 : percentage;
};

import { DynamicLoadingParams } from 'front-commons/hooks/useDynamicLoading/interfaces';
import { DistributorsFiltersEnum, DistributorsFiltersExhibitionType } from 'services/pos/interfaces';

export type BusinessUnitNameTypes = 'Neo Química' | 'Marcas';

export interface DistributorsProps {
	initialTabOpened?: string;
}

export interface DistributorProps {
	id: string;
	minimumValue: number;
	order: number;
	summarizedCorporateName: string;
	commercialCenter: string;
}

export enum BusinessUnitResponseEnum {
	BusinessUnitId = 'businessUnitId',
	BusinessUnitName = 'businessUnitName',
	Distributors = 'distributors',
	IsPED = 'pedEnabled',
}

export interface BusinessUnitResponse {
	[BusinessUnitResponseEnum.BusinessUnitId]: string;
	[BusinessUnitResponseEnum.BusinessUnitName]: BusinessUnitNameTypes;
	[BusinessUnitResponseEnum.Distributors]: DistributorProps[];
	[BusinessUnitResponseEnum.IsPED]: boolean;
}

export type DistributorsLoadingTypes = DynamicLoadingParams<'POS' | 'DISTRIBUTORS'>;

export interface CustomerFiltersDataParams {
	[DistributorsFiltersEnum.CommercialFlag]: string | undefined;
	[DistributorsFiltersEnum.Region]: string[] | undefined;
	pos: string | string[] | undefined;
	exhibitionType?: DistributorsFiltersExhibitionType;
}

export interface DistributorsFiltersProps {
	initialPos?: string;
	hasFiltersChanged?: boolean;

	applyFilters(simplePosId?: string): void
	updateActualFilters(payload: Partial<CustomerFiltersDataParams>): void
}
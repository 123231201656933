/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Flex, Icon, Dropdown, Typography, notify } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { findCategories } from 'services/category';
import { FindCategoriesResponse } from 'services/category/interfaces';
import { selectContentGTM } from 'shared/gtm';
import usePos from 'stores/pos';
import { MenuCategoriesProps } from './interfaces';
import { BoxButtonScroll, Container, List, TriggerContainer, DropdownMobile, FlexLeft } from './styles';

export default function MenuCategories({ forceExpanded, hiddenTrigger, isMobile = false }: MenuCategoriesProps) {
	const { posStore } = usePos();
	const pos = posStore.selectedPos;

	const [categories, setCategories] = useState<FindCategoriesResponse[]>([]);
	const [expanded, setExpanded] = useState(false);
	const [categoryId, setCategoryId] = useState('');
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.large})`);

	const location = useLocation();

	const refListRight = useRef<HTMLDivElement>(null);
	const refListLeft = useRef<HTMLDivElement>(null);

	const handleFindCategories = useCallback(async () => {
		if (!pos) return;

		try {
			const data = await findCategories(pos?.id);
			setCategories(data);
		} catch (error) {
			notify.negative({ description: 'Ocorreu um erro ao carregar as categorias. Tente novamente mais tarde.' });
		}
	}, [pos?.id]);

	const scrollDown = useCallback(
		(sideList: string) => {
			let menuRef = null;
			if (sideList === 'left') {
				menuRef = refListLeft.current;
			} else {
				menuRef = refListRight.current;
			}
			const totalHeight = menuRef?.scrollHeight || 0;
			const visibleHeight = menuRef?.clientHeight || 0;
			const maxScroll = totalHeight - visibleHeight;

			const scrollIncrement = 70;

			if (menuRef && typeof menuRef.scrollTop === 'number') {
				if (Math.ceil(menuRef.scrollTop) < maxScroll) {
					menuRef.scrollTo({ top: Math.ceil(menuRef.scrollTop) + scrollIncrement, behavior: 'smooth' });
				}
			}
		},
		[refListRight],
	);

	useEffect(() => {
		handleFindCategories();
	}, []);

	useEffect(() => {
		setExpanded(false);
	}, [location.pathname, location.search]);

	useEffect(() => {
		if (forceExpanded !== undefined) {
			setExpanded(forceExpanded);
		}
	}, [forceExpanded]);

	useEffect(() => {
		document.body.style.overflow = expanded && isMobile ? 'hidden' : 'initial';
	}, [isMobile, expanded]);

	if (isMobile && expanded) {
		return (
			<DropdownMobile>
				<List>
					{categories.map((category) => (
						<div key={category.categoryId} onClick={() => selectContentGTM('header_menu', category.categoryName)}>
							<Button
								variant="text"
								rightIcon={{
									name: 'chevron_right',
								}}
								href={`/categorias/${category.categoryName.slugify()}?categoria=${category.categoryId}`}
							>
								<Typography>{category.categoryName.capitalize()}</Typography>
							</Button>
						</div>
					))}
				</List>
			</DropdownMobile>
		);
	}

	return (
		<Dropdown
			triggerContent={
				<TriggerContainer
					display={hiddenTrigger ? 'none' : 'flex'}
					expanded={expanded}
					data-state={expanded ? 'open' : 'closed'}
				>
					<Typography
						whiteSpace="nowrap"
						variant={{ small: 'ParagraphSmall/Regular', medium: 'Paragraph/Regular' }}
						color={{ small: '--text-primary', medium: '--text-invert' } as any}
					>
						Nossos produtos
					</Typography>
					<Icon name="expand_more" color="--text-invert" size="16px" display={{ small: 'none', medium: 'initial' }} />
				</TriggerContainer>
			}
			onOpenChange={() => setExpanded((prevState) => !prevState)}
			side="bottom"
			offset={4}
			useExpanded
			expanded={expanded}
			desktopFull={!isDesktop}
			isMenuButton
			radiusFullDesktop
		>
			<Container>
				<FlexLeft direction="column" width="100%" gap="0px">
					<List ref={refListLeft}>
						{categories.map((category) => (
							// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
							<button
								key={category.categoryId}
								onClick={() => selectContentGTM('header_menu', category.categoryName)}
								onMouseOver={() => setCategoryId(category.categoryId)}
								type="button"
							>
								<a href={`/categorias/${category.categoryName.slugify()}?categoria=${category.categoryId}`}>
									<Typography>{category.categoryName.capitalize()}</Typography>

									<Icon name="chevron_right" color="--text-primary" />
								</a>
							</button>
						))}
					</List>

					{categories.length > 9 && (
						<BoxButtonScroll>
							<Button onClick={() => scrollDown('left')} variant="none">
								<Icon name="expand_more" />
							</Button>
						</BoxButtonScroll>
					)}
				</FlexLeft>

				{categoryId && categories.find((category) => category.categoryId === categoryId)?.brands.length! > 0 && (
					<Flex display={{ small: 'none', medium: 'flex' }} direction="column" width="100%" gap="0px">
						<List ref={refListRight} key={categoryId}>
							{categories
								.find((category) => category.categoryId === categoryId)
								?.brands.map((item) => (
									<a
										key={item.id}
										href={`/categorias/${categories
											.find((i) => i.categoryId === categoryId)!
											.categoryName?.slugify()}?categoria=${
											categories.find((i) => i.categoryId === categoryId)?.categoryId
										}&marcas=${encodeURIComponent(item.name)}`}
										onClick={() => selectContentGTM('header_menu', item.name)}
										rel="noreferrer"
									>
										<Typography>{item.name.capitalize()}</Typography>
									</a>
								))}
						</List>

						{categories.find((category) => category.categoryId === categoryId)?.brands.length! > 9 && (
							<BoxButtonScroll>
								<Button onClick={() => scrollDown('right')} variant="none">
									<Icon name="expand_more" />
								</Button>
							</BoxButtonScroll>
						)}
					</Flex>
				)}
			</Container>
		</Dropdown>
	);
}

/* CacheBuster component */
import { Component } from 'react';
import version from '../version';
window.appVersion = version.frontVersion;

const semverGreaterThan = (versionA, versionB) => {
	const versionsA = versionA.split(/\./g);

	const versionsB = versionB.split(/\./g);
	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());

		const b = Number(versionsB.shift());
		// eslint-disable-next-line no-continue
		if (a === b) continue;
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};

export default class CacheBuster extends Component {
	constructor(props) {
		super(props);
		this.attempts = window.sessionStorage.getItem('ph-attempts') || 0;
		this.state = {
			loading: true,
			isLatestVersion: false,
			refreshCacheAndReload: async () => {
				console.log('Clearing cache and hard reloading...');
				if (caches) {
					const names = await caches.keys();
					await Promise.all(names.map((name) => caches.delete(name)));
				}
				if (this.attempts < 1) {
					window.sessionStorage.setItem('ph-attempts', ++this.attempts);
					window.location.reload(true);
				}
			},
		};
	}

	componentDidMount() {
		fetch('/meta.json')
			.then((response) => response.json())
			.then((meta) => {
				const latestVersion = meta.version;
				const currentVersion = window.appVersion;

				const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
				if (shouldForceRefresh) {
					console.log(`We have a new version - ${latestVersion}. Should force refresh`);
					this.setState({ isLatestVersion: false });
				} else {
					console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
					this.setState({ isLatestVersion: true });
				}
			})
			.catch(console.error)
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	render() {
		const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
		return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
	}
}

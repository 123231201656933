import { Button, Counter } from 'front-commons/ds';
import { AddToBasketButtonRenderProps } from './interfaces';

export default function AddToBasketButtonRender({
	loading,
	loadingBasket,
	disabled,
	quantity,
	contextQuantity,
	counterOptions,
	onAdd,
	onChange,
	onRemove,
	onBlur,
	hasPermission,
	allowZero = false,
	width,
	label,
	maxQuantity,
}: AddToBasketButtonRenderProps) {
	const loadingAddButton = loading === 'add' || loadingBasket === 'full' || !!(!quantity.amount && contextQuantity);

	const forceAmount = (amount: number | '') => {
		// when the input is empty
		if (!amount && amount !== 0) return '';

		// when the amount is above maxQuantity
		if (!!maxQuantity && amount > maxQuantity) return maxQuantity;

		return amount;
	};

	if (quantity.method === 'typing' || Number(quantity.amount) > 0 || allowZero) {
		return (
			<Counter
				quantity={quantity.amount}
				initialQuantity={contextQuantity}
				onAdd={() => onChange({ method: 'increment' })}
				onRemove={() => onRemove()}
				onChange={(value) => onChange({ amount: forceAmount(value), method: 'typing' })}
				onBlur={onBlur}
				loading={loading}
				disabled={disabled}
				hasPermission={hasPermission}
				data-testid="add-to-cart-counter"
				maxQuantity={maxQuantity || undefined}
				{...counterOptions}
			/>
		);
	}

	return (
		<Button
			onClick={() => {
				onAdd();
			}}
			width="100%"
			maxWidth={width ? undefined : { small: '124px', medium: '128px' }}
			variant="primary"
			rightIcon={label ? undefined : { name: 'shopping_cart' }}
			loading={loadingAddButton}
			disabled={disabled}
			hasPermission={hasPermission}
			data-testid="add-to-cart"
		>
			{label || 'Adicionar'}
		</Button>
	);
}

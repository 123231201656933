import { Summary } from 'contexts/Promotion/interfaces';
import { ProductUnavailableProps } from 'services/basket/interfaces';
import { DiscountRange, GetPromotionInfoResponse } from 'services/products/interfaces';
import { DialogState, GetCurrentRangeProps, GetDialogProps } from './interfaces';

export const getDaysToEnd = (to: string) => {
	const { days } = Date.getDiff({ to });
	return days;
};

export const getQuantityMessage = (
	fixedAmount: boolean,
	quantity: number,
	price: number,
	minimumQuantity: number | undefined,
	hasValidDistributors: boolean,
) => {
	if (!price || !hasValidDistributors) return 'Qtd mínima: 0';
	if (fixedAmount) return `Qtd fixa: ${quantity}`;
	return minimumQuantity ? `Qtd mínima: ${minimumQuantity}` : 'Sem qtd mínima';
};

export const getDiscountPrice = (discount: number, price: number) => (discount / 100 - 1) * -1 * price;

export const getSummaryQuantities = (summary: Summary[]) => summary.reduce((acc, curr) => curr.quantity + acc, 0);

export const getSummaryBrutePrice = (summary: Summary[]) =>
	summary.reduce((acc, curr) => curr.quantity * curr.price + acc, 0);

const fallbackValues = {
	discount: 0,
	taxSubstitution: 0,
};

const getRangeValues = (ranges: DiscountRange[], target: number) =>
	ranges.find(({ from, to }) => {
		if (to) return target >= from && target <= to;
		return target >= from;
	}) || fallbackValues;

export const getCurrentRange = ({
	ranges,
	quantity,
	totalPrice,
	mixedRanges,
	productPrices,
	rangeByQuantity,
	productQuantities,
	quantityInBasket,
	progressiveDiscount,
}: GetCurrentRangeProps) => {
	if (ranges?.length === 1 && !mixedRanges?.length) {
		const [{ discount, taxSubstitution }] = ranges;

		return {
			discount,
			taxSubstitution,
		};
	}

	if (mixedRanges?.length)
		return rangeByQuantity
			? getRangeValues(mixedRanges, progressiveDiscount ? quantityInBasket : productQuantities)
			: getRangeValues(mixedRanges, productPrices);

	return getRangeValues(ranges || [], rangeByQuantity ? quantity : totalPrice);
};

export const getCurrentComponentRange = (
	rangeByQuantity: boolean,
	progressiveDiscount: boolean,
	summary: Summary[],
	currentQuantityInBasket = 1,
) => {
	if (rangeByQuantity)
		return progressiveDiscount ? currentQuantityInBasket : getSummaryQuantities(summary) * currentQuantityInBasket;
	return getSummaryBrutePrice(summary) * currentQuantityInBasket;
};

export const hasQuantityChanged = (currentData: Summary[], promotionId: string, initialData: ProductUnavailableProps[]) => {
	const productsInCombo = initialData?.find(({ productId }) => productId === promotionId)?.productsInCombo;

	const hasAnyChangesInQuantity =
		productsInCombo?.map(
			({ quantity, productId }) =>
				quantity === currentData.find((product) => product.productId === productId)?.quantity,
		) || [];

	if (currentData?.length !== hasAnyChangesInQuantity.length) {
		const productsWithQuantitiesZero =
			currentData?.reduce<boolean[]>((prev, { productId, quantity }) => {
				if (!productsInCombo?.find((p) => p.productId === productId)) {
					prev.push(quantity === 0);
				}
				return prev;
			}, []) || [];

		const hasAnyChanges = [...productsWithQuantitiesZero, ...(hasAnyChangesInQuantity || [])];
		return !hasAnyChanges.every(Boolean);
	}

	return !hasAnyChangesInQuantity.every(Boolean);
};

export const getDialogProps = (state: DialogState, action?: GetDialogProps) => {
	if (state === 'conflict')
		return {
			heading: { title: 'Conflito de produtos' },
			content: {
				description:
					'Já existe esse produto em seu carrinho. Para comprá-lo com outra condição comercial, você deve removê-lo do carrinho.',
			},
			footer: {
				primaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
				secondaryButton: { label: 'Cancelar' },
			},
		};

	if (state === 'distributor')
		return {
			heading: {
				title: 'Deseja continuar?',
				showCloseButton: true,
			},
			content: {
				description:
					'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
			},
			onClose: action?.handleDialogClose,
			footer: {
				primaryButton: { label: 'Continuar', onClick: action?.handleRemovePromotion },
				secondaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
			},
		};

	if (state === 'no_value')
		return {
			heading: {
				title: 'Combo sem quantidade',
			},
			content: {
				description: 'Adicione a quantidade dos produtos desejada para continuar',
			},
			footer: {
				primaryButton: { label: 'Entendi' },
			},
		};

	if (state === 'removing_unavailable_promotion')
		return {
			heading: {
				title: 'Deseja continuar?',
			},
			content: {
				description:
					'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
			},
			onClose: action?.handleDialogClose,
			footer: {
				primaryButton: { label: 'Cancelar' },
				secondaryButton: { label: 'Continuar', onClick: action?.handleRemovePromotion },
			},
		};

	return {
		heading: {
			title: 'Atualizar carrinho',
		},
		content: {
			description: 'Foram adicionados mais itens ao seu combo, para prosseguir você deve atualizar o seu carrinho.',
		},
		footer: {
			primaryButton: { label: 'Voltar a promoção' },
		},
	};
};

export const hasUnableToUpdate = (
	hasChanged: boolean,
	currentQuantityInBasket: number | undefined,
	hasValidDistributors: boolean,
) => {
	if (!hasValidDistributors) {
		return true;
	}
	if (currentQuantityInBasket) {
		if (hasChanged) return false;
		return true;
	}

	return false;
};

export const getInitialQuantity = (
	fixedAmount: boolean,
	minimumQuantity: number | undefined,
	flexBasketQuantity: number | undefined,
	productQuantity: number,
	hasValidDistributors: boolean,
) => {
	if (!hasValidDistributors) return 0;
	if (fixedAmount) return minimumQuantity || 1;
	return flexBasketQuantity || productQuantity;
};

export const handlerDistributorValidity = (data: GetPromotionInfoResponse) =>
	!!data?.distributors?.length && data?.distributors.some(({ status }) => status !== 'UNAVAILABLE');

export const getRemainingDaysText = (date: string) => {
	const daysToEnd = getDaysToEnd(date);

	if (daysToEnd < 0) return '';

	if (daysToEnd === 0) return 'Último dia da promoção!';

	return `Falta${daysToEnd > 1 ? 'm' : ''} ${daysToEnd} dia${daysToEnd > 1 ? 's' : ''} para terminar a promoção.`;
};

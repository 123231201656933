import { Tag } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { selectContentGTM } from 'shared/gtm';
import useCatalog from 'stores/catalog';
import { getPropertiesByItem } from './helper';
import { CatalogMenuProps } from './interfaces';
import { CatalogMenuContainer, CatalogMenuItem } from './styles';
import MenuCategories from '../MenuCategories';

export default function CatalogMenu({ variant, display }: CatalogMenuProps) {
	const { catalogStore, isSuggestionsProductNotifierExpired } = useCatalog();
	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);

	const getCounterByItem = (href: string, counter?: number) => {
		if (!!counter && href === '/sugestoes-de-pedidos') {
			return isSuggestionsProductNotifierExpired;
		}

		return !!counter;
	};

	return (
		<CatalogMenuContainer display={display} gap="0px" justifyContent={{ large: 'center' }} variant={variant}>
			<MenuCategories hiddenTrigger={!isTablet} />

			{catalogStore.catalogMenuItems.map(({ href, label, status, counter }) => (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<div key={label} onClick={() => selectContentGTM('header_menu', label)}>
					<CatalogMenuItem {...getPropertiesByItem(href, status)} hasTag={getCounterByItem(href, counter)}>
						{label}{' '}
						{getCounterByItem(href, counter) && (
							<Tag
								label={String(counter)}
								bgColor="--semantic-info-weak"
								labelOptions={{ color: '--semantic-info-text', variant: 'ParagraphSmall/Regular' }}
							/>
						)}
					</CatalogMenuItem>
				</div>
			))}
		</CatalogMenuContainer>
	);
}

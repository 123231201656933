import { Button, Icon } from 'front-commons/ds';
import React from 'react';
import { TrashButtonProps } from './interfaces';

export default function TrashButton({ ...buttonProps }: TrashButtonProps) {
	return (
		<Button {...buttonProps}>
			<Icon name="delete" color="--text-primary" />
		</Button>
	);
}

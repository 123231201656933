import { notify } from 'front-commons/ds';
import { useDynamicLoading, useFetch } from 'front-commons/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import ActionButtons from 'containers/ActionButtons/ImportPromotionsActionButtons';
import PromotionDetailsDrawer from 'containers/Contents/PromotionDetailsDrawer';
import { PromotionDetailsDrawerProps } from 'containers/Contents/PromotionDetailsDrawer/interfaces';
import { manageBasketProductAmount, validateBasketProductAmount } from 'services/basket';
import { updateOrderImportPromotion, updateProductByPosId } from 'services/import';
import { getSearchedProductsDrawerPromotion } from 'services/products';
import { DistributorsProps, GetPromotionInfoResponse } from 'services/products/interfaces';
import { reorderItems } from 'shared/promotionDrawer';
import useBasket from 'stores/basket';
import { PromotionDetailsSummaryParams } from 'stores/catalog/interfaces';
import useDialog from 'stores/dialog';
import useDrawer from 'stores/drawer';
import { getDialogProps, handlerDistributorValidity, getDiscountPrice } from './helpers';
import { DialogState, ImportPromotionDetailDrawerContentProps } from './interfaces';

export default function ImportPromotionDetailDrawerContent({
	data,
	posId,
	prevData,
	promotionId,
	sourceProductId,
	showReturnButton,
	importDistributor,
	orderImportProductId,
	disableDistributorSelector,

	handleRefetchItemList,
	handleChangeDistributorId,
}: ImportPromotionDetailDrawerContentProps) {
	const hasRendered = useRef(false);

	const { handleOpenDialog } = useDialog();
	const { isDrawerOpen, handleCloseAllDrawers } = useDrawer();
	const { handleRemoveFromBasket } = useBasket();
	const { handleLoading } = useDynamicLoading<'refetch' | 'full' | ''>();
	const [fixedQuantity, setFixedQuantity] = useState(prevData?.quantity || 1);

	const [notFoundProducts, setNotFoundProducts] = useState(false);

	const isOpen = isDrawerOpen('import-promotion-details-drawer');

	const initialValues = {
		promotionData: {} as GetPromotionInfoResponse,
		productsSummary: [],
		selectedDistributorId: prevData?.distributorId || importDistributor?.distributorId || '',
		hasRendered: false,
	};

	const [selectedDistributorId, setSelectedDistributorId] = useState(initialValues.selectedDistributorId);
	const [promotionData, setPromotionData] = useState(initialValues.promotionData);
	const [productsSummary, setProductsSummary] = useState<PromotionDetailsSummaryParams[]>(
		initialValues.productsSummary,
	);

	const resetValues = useCallback(() => {
		if (Object.keys(promotionData).length) setPromotionData(initialValues.promotionData);
		if (productsSummary.length) setProductsSummary(initialValues.productsSummary);
		if (selectedDistributorId !== '') setSelectedDistributorId('');
		if (hasRendered.current) hasRendered.current = initialValues.hasRendered;
	}, [promotionData, productsSummary, selectedDistributorId]);

	const { fetch: fetchUpdatePromotion } = useFetch({
		fetchFunction: updateProductByPosId,
		onBeforeFetch() {
			handleLoading().set(['refetch']);
		},
		onSuccess() {
			handleLoading().remove(['refetch']);
			handleRefetchItemList();
		},
		onError() {
			handleLoading().remove(['refetch']);
		},
	});

	const handleUpdateSummary = useCallback((newSummary: PromotionDetailsSummaryParams) => {
		setProductsSummary((prevState) =>
			prevState.map((item) => (item.productId === newSummary.productId ? newSummary : item)),
		);
	}, []);

	const selectedDistributor: Partial<PromotionDetailsDrawerProps['selectedDistributor']> = {
		distributorId: selectedDistributorId || initialValues.selectedDistributorId,
	};

	const productsInComboPrices =
		promotionData?.productsInCombo?.map(({ productId, distributorsPrice }) => ({
			price:
				distributorsPrice.find(({ distributorId }) => distributorId === selectedDistributor.distributorId)?.price || 0,
			productId,
		})) || [];

	const basketData: PartialNested<PromotionDetailsDrawerProps['basketData']> = {
		baskets: [
			{
				products: [
					{
						id: promotionData?.id,
						quantity: prevData?.quantity,
						productsInCombo: promotionData?.productsInCombo?.map((product) => {
							const hasChanged = prevData?.products.find(({ productId }) => productId === product.productId);

							if (hasChanged)
								return {
									...product,
									quantity: hasChanged.quantity,
								};

							return product;
						}),
						fixedAmount: promotionData?.fixedAmount,
						progressiveDiscount: promotionData?.progressiveDiscount,
						progressiveDiscountProductMix: promotionData?.progressiveDiscountProductMix,
					},
				],
				distributorId: selectedDistributor.distributorId,
			},
		],
	};

	const { brute, discount, liquid, tax } = (() =>
		productsSummary.reduce(
			({ brute, discount, liquid, tax }, curr) => {
				const quantityInBasket = fixedQuantity;

				const totalBrute = curr.quantity * curr.price * quantityInBasket;
				const totalDiscount = curr.quantity * getDiscountPrice(curr.discount, curr.price) * quantityInBasket;
				const totalTax = curr.price ? curr.quantity * curr.tax * quantityInBasket : 0;

				return {
					discount: totalBrute - totalDiscount + discount,
					tax: totalTax + tax,
					brute: totalBrute + brute,
					liquid: totalDiscount + totalTax + liquid,
				};
			},
			{
				discount: 0,
				tax: 0,
				brute: 0,
				liquid: 0,
			},
		))();

	const handleSortItems = useCallback(
		(sortIdentifier: number) => {
			const reorderedItems = reorderItems(
				promotionData.productsInCombo,
				selectedDistributor.distributorId!,
				sortIdentifier,
				productsSummary.filter((i) => i.quantity > 0).map((item) => item.productId),
			);

			setPromotionData({ ...promotionData, productsInCombo: reorderedItems || promotionData.productsInCombo });
		},
		[promotionData, productsSummary, promotionData, selectedDistributor],
	);

	const getProductValidity = (productId: string) =>
		promotionData?.productsInCombo
			?.find((p) => p.productId === productId)
			?.distributorsPrice.find((i) => i.distributorId === selectedDistributor?.distributorId)?.valid;

	const handleRemovePromotion = useCallback(async () => {
		if (disableDistributorSelector) {
			try {
				await handleRemoveFromBasket(promotionId, posId);
				handleCloseAllDrawers();
				notify.positive({ description: 'Promoção removida com sucesso.' });
			} catch {
				notify.negative({ description: 'Não foi possivel remover a promoção.' });
			}

			return;
		}

		fetchUpdatePromotion({
			removed: true,
			orderImportProductId: prevData?.orderImportProductId,
		});
	}, [prevData]);

	const handleChangeDistributor = async (distributor: DistributorsProps) => {
		if (distributor.distributorId === selectedDistributorId) return;

		try {
			handleChangeDistributorId?.(distributor.distributorId);
			setSelectedDistributorId?.(distributor.distributorId);

			if (!hasRendered.current || disableDistributorSelector) {
				hasRendered.current = true;
				return;
			}

			if (!prevData) return;

			const requestData = {
				distributorId: distributor.distributorId,
				orderImportProductId: prevData.orderImportProductId,
				quantity: prevData.quantity || 1,
				removed: false,
			};

			await updateOrderImportPromotion(requestData);
			notify.positive({ description: 'Distribuidor alterado com sucesso.' });

			handleRefetchItemList();
		} catch (error) {
			notify.negative({ description: 'Não foi possivel alterar o distribuidor do produto.' });
		}
	};

	const handleSetDialogState = (state: DialogState) => {
		handleOpenDialog({
			...getDialogProps(state, {
				handleRemovePromotion,
			}),
		});
	};

	const [searchedData, setSearchedData] = useState<GetPromotionInfoResponse | undefined>(undefined);

	const getSearchedProducts = useCallback(
		async (searchedPromotionId: string, searchedPosId: string, query: string) => {
			if (query === '') {
				setSearchedData(undefined);
				setNotFoundProducts(false);
				return;
			}

			try {
				handleLoading().set(['refetch']);

				const { productIds } = await getSearchedProductsDrawerPromotion(searchedPosId, searchedPromotionId, query);

				if (productIds.length === 0) {
					setSearchedData(undefined);
					setNotFoundProducts(true);
					return;
				}

				setSearchedData({
					...data,
					productsInCombo: data.productsInCombo?.filter((p) => productIds.includes(p.productId)),
				});
				setNotFoundProducts(false);
			} catch (error) {
				notify.negative({ description: 'Produto não encontrado' });
			} finally {
				handleLoading().remove(['refetch']);
			}
		},
		[data],
	);

	const updatedProductsInCombo = productsSummary.map((product) => ({
		productId: product.productId,
		quantity: product.quantity,
	}));

	const handleFlexAdd = async () => {
		try {
			handleLoading().set(['refetch']);

			const requestData = {
				quantity: 1,
				productId: promotionId,
				distributorId: selectedDistributor.distributorId as string,
				productsInCombo: updatedProductsInCombo,
				suggestionGroupId: data.suggestionGroupId,
				suggestionGroupName: data.suggestionGroupName,
			};

			const hasConflict = await validateBasketProductAmount(posId, requestData);

			if (hasConflict) {
				handleSetDialogState('conflict');
				return;
			}

			await manageBasketProductAmount(posId, requestData);

			notify.positive({ description: `Promoção atualizada com sucesso` });

			handleRefetchItemList();
		} finally {
			handleLoading().remove(['refetch']);
		}
	};

	const handleFixedAmountQuantityChange = useCallback(
		(quantity: number) => {
			if (data.fixedAmount) setFixedQuantity(quantity);
		},
		[data],
	);

	useEffect(() => {
		if (promotionData?.productsInCombo?.length) {
			setProductsSummary(
				promotionData?.productsInCombo?.map(
					({
						productId,
						productInfo: { brandName, categoryName, description, ean13 },
						quantity,
						distributorsPrice,
					}) => {
						return {
							brandName,
							categoryName,
							description,
							discount: 0,
							distributors: distributorsPrice,
							ean13,
							price:
								distributorsPrice.find(({ distributorId }) => {
									return distributorId === selectedDistributor.distributorId;
								})?.price || 0,
							productId,
							quantity: prevData?.products.find(({ productId: id }) => id === productId)?.quantity || quantity,
							tax: 0,
						};
					},
				),
			);
		}
	}, [promotionData, prevData]);

	useEffect(() => {
		setPromotionData(data);
	}, [data]);

	useEffect(() => {
		if (!isOpen) resetValues();
	}, [isOpen]);

	if (!data || !promotionData?.productsInCombo?.length || !productsSummary.length) return null;

	return (
		<PromotionDetailsDrawer
			CTAComponent={
				disableDistributorSelector ? undefined : (
					<ActionButtons
						posId={posId}
						isFlex={promotionData.fixedAmount}
						hasValue={!!liquid}
						promotionId={promotionId}
						comboProducts={updatedProductsInCombo}
						distributorId={selectedDistributor.distributorId as any}
						suggestionGroupId={data.suggestionGroupId}
						suggestionGroupName={data.suggestionGroupName}
						handleSetDialogState={handleSetDialogState}
						hasValidDistributors={handlerDistributorValidity(promotionData)}
						orderImportProductId={prevData?.orderImportProductId || orderImportProductId}
						handleRefetchItemList={handleRefetchItemList}
						handleQuantityImportChange={handleFixedAmountQuantityChange}
					/>
				)
			}
			brute={brute}
			discount={discount}
			liquid={liquid}
			tax={tax}
			posId={posId}
			loadingPromotion={handleLoading().get(['refetch']) ? 'refetch' : ''}
			promotionId={promotionData.id}
			sourceProductId={sourceProductId}
			notFoundProducts={notFoundProducts}
			showReturnButton={showReturnButton}
			handleChangeDistributor={handleChangeDistributor}
			handleRemovePromotion={handleRemovePromotion}
			handleSortItems={handleSortItems}
			updateSummary={handleUpdateSummary}
			getProductValidity={getProductValidity}
			productsInComboPrices={productsInComboPrices}
			summary={productsSummary}
			basketData={basketData as any}
			selectedDistributor={selectedDistributor as any}
			data={searchedData || promotionData}
			getSearchedProducts={getSearchedProducts}
			disableDistributorSelector={disableDistributorSelector}
			// props used in product revision step (uses basket)
			updatedProductsInCombo={updatedProductsInCombo as any}
			handleAddToBasketClick={handleFlexAdd}
			flexAddLoading={handleLoading().get(['refetch'])}
			// unused on import context
			loadingBasket={false}
			getFlexQuantityInBasket={() => 0}
			dispatchGTMBasketEvent={() => {}}
			distributorWaiting={false}
		/>
	);
}

import { Summary } from 'contexts/Promotion/interfaces';
import { DiscountRange, GetPromotionInfoResponse } from 'services/products/interfaces';
import { DialogState, GetCurrentRangeProps, GetDialogProps } from './interfaces';

export const getDiscountPrice = (discount: number, price: number) => (discount / 100 - 1) * -1 * price;

export const getSummaryQuantities = (summary: Summary[]) => summary.reduce((acc, curr) => curr.quantity + acc, 0);

export const getSummaryBrutePrice = (summary: Summary[]) =>
	summary.reduce((acc, curr) => curr.quantity * curr.price + acc, 0);

const fallbackValues = {
	discount: 0,
	taxSubstitution: 0,
};

const getRangeValues = (ranges: DiscountRange[], target: number) =>
	ranges.find(({ from, to }) => {
		if (to) return target >= from && target <= to;
		return target >= from;
	}) || fallbackValues;

export const getCurrentRange = ({
	ranges,
	quantity,
	totalPrice,
	mixedRanges,
	productPrices,
	rangeByQuantity,
	productQuantities,
	quantityInBasket,
	progressiveDiscount,
	hasRangeProduct,
	progressiveDiscountProductMix,
}: GetCurrentRangeProps) => {
	if (ranges?.length === 1 && !mixedRanges?.length) {
		const [{ discount, taxSubstitution }] = ranges;

		return {
			discount,
			taxSubstitution,
		};
	}

	if (progressiveDiscountProductMix && hasRangeProduct && ranges)
		return getRangeValues(ranges, rangeByQuantity ? productQuantities : productPrices);

	if (mixedRanges?.length)
		return rangeByQuantity
			? getRangeValues(mixedRanges, progressiveDiscount ? quantityInBasket : productQuantities)
			: getRangeValues(mixedRanges, productPrices);

	return getRangeValues(ranges || [], rangeByQuantity ? quantity : totalPrice);
};

export const getDialogProps = (state: DialogState, action?: GetDialogProps) => {
	if (state === 'conflict')
		return {
			heading: { title: 'Conflito de produtos' },
			content: {
				description:
					'Já existe esse produto em seu carrinho. Para comprá-lo com outra condição comercial, você deve removê-lo do carrinho.',
			},
			footer: {
				primaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
				secondaryButton: { label: 'Cancelar' },
			},
		};

	if (state === 'distributor')
		return {
			heading: {
				title: 'Deseja continuar?',
				showCloseButton: true,
			},
			content: {
				description:
					'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
			},
			onClose: action?.handleDialogClose,
			footer: {
				primaryButton: { label: 'Continuar', onClick: action?.handleRemovePromotion },
				secondaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
			},
		};

	if (state === 'no_value')
		return {
			heading: {
				title: 'Combo sem quantidade',
			},
			content: {
				description: 'Adicione a quantidade dos produtos desejada para continuar',
			},
			footer: {
				primaryButton: { label: 'Entendi' },
			},
		};

	return {
		heading: {
			title: 'Atualizar carrinho',
		},
		content: {
			description: 'Foram adicionados mais itens ao seu combo, para prosseguir você deve atualizar o seu carrinho.',
		},
		footer: {
			primaryButton: { label: 'Voltar a promoção' },
		},
	};
};

export const getInitialQuantity = (
	fixedAmount: boolean,
	minimumQuantity: number | undefined,
	flexBasketQuantity: number | undefined,
	productQuantity: number,
	hasValidDistributors: boolean,
) => {
	if (!hasValidDistributors) return 0;
	if (fixedAmount) return minimumQuantity || 1;
	return flexBasketQuantity || productQuantity;
};

export const handlerDistributorValidity = (data: GetPromotionInfoResponse) =>
	!!data?.distributors?.length && data?.distributors.some(({ status }) => status !== 'UNAVAILABLE');

import { Grid, Typography } from 'front-commons/ds';
import { FooterLinksProps } from './interfaces';
import { ItemList } from './styles';

export default function FooterLinks({ ...data }: FooterLinksProps) {
	const items = data.props;

	return (
		<>
			{items.map((item, index) => (
				<Grid key={index} gap="16px">
					<Typography variant="Headline/H2 Bold" color="--text-invert" as="span">
						{item.title}
					</Typography>
					<Grid as="ul" gap="16px">
						{item.content.map((itemData, i) => (
							<ItemList key={i}>
								<a href={itemData.link} target={itemData.isTarget ? '_blank' : '_self'} rel="noreferrer">
									<Typography color="--text-invert" as="span">
										{itemData.text}
									</Typography>
								</a>
							</ItemList>
						))}
					</Grid>
				</Grid>
			))}
		</>
	);
}

import { Grid, Typography } from 'front-commons/ds';
import ContactUs from 'containers/ContactUs';
import FooterLinks from 'containers/FooterLinks';
import { data } from './helpers';
import { FooterProps } from './interfaces';
import { FooterContainer, FooterStyle, GridList, LogoStyled } from './styles';

export default function Footer({ backgroundColor, icon }: FooterProps) {
	return (
		<FooterStyle backgroundColor={backgroundColor}>
			<Grid as={FooterContainer} gap="32px" columns={{ medium: '1fr', large: '1fr 1.7fr' }}>
				{icon ? (
					<div>{icon}</div>
				) : (
					<LogoStyled
						format="row"
						type="neutral_white"
						maxWidth={{ small: '178px', medium: '223px' }}
						loadingProps={{ maxWidth: { small: '178px', medium: '223px' } }}
					/>
				)}

				<GridList gap="32px" columns={{ small: '1fr', medium: '1fr 1fr 2fr' }}>
					<FooterLinks props={data} />
					<Grid gap="16px">
						<Typography variant="Headline/H2 Bold" color="--text-invert" as="span">
							Atendimento
						</Typography>
						<ContactUs color="--text-invert" />
					</Grid>
				</GridList>
			</Grid>
		</FooterStyle>
	);
}
